import { gql } from '@apollo/client';
import { createCell } from '@lib/api-client';
import { useCheckAccess } from '@lib/authentication';
import { escapeStringRegexp } from '@lib/strings';
import { Grid, Skeleton } from '@mui/material';
import { AddStudyButton } from './AddStudyButton';
import { StudyCard } from './StudyCard';

export const QUERY = gql`
  query WEB_LandingPageStudiesList($filter: StudyFilter) {
    studies(filter: $filter) {
      nodes {
        id
        sponsor
        internalStudyName
        id
        status
        createdAt
        featureOptions {
          studyId
          recruitmentPortal
          consenteesPortal
          analyticsPortal
        }
        sites {
          siteId
        }
        locales {
          id
        }
      }
    }
  }
`;

export const beforeQuery = ({ statuses }) => ({
  variables: {
    filter: { statuses, limit: 0 },
  },
  fetchPolicy: 'cache-and-network',
});

export const afterQuery = data => {
  return { studies: data?.studies?.nodes ?? [] };
};

export function Success({ studies, filterText, onAddStudy }) {
  const { checkAccess } = useCheckAccess();

  const regExp = new RegExp(escapeStringRegexp(filterText), 'i');
  const visibleStudies = studies.filter(
    study => regExp.test(study.internalStudyName) || regExp.test(study.id),
  );

  return (
    <>
      {checkAccess({ actions: ['study:create'] }) && (
        <ListGridCell>
          <AddStudyButton onClick={onAddStudy} />
        </ListGridCell>
      )}

      {visibleStudies.map(item => (
        <ListGridCell key={item.id}>
          <StudyCard {...item} />
        </ListGridCell>
      ))}
    </>
  );
}

export function Loading() {
  return Array.from({ length: 7 }, (_, i) => (
    <ListGridCell key={i}>
      <Skeleton variant="rectangular" height={167} />
    </ListGridCell>
  ));
}

const ListGridCell = ({ children }) => (
  <Grid sx={{ width: 296 }} item xs={12} sm={6} lg={4} xl={3}>
    {children}
  </Grid>
);

export default createCell({
  QUERY,
  beforeQuery,
  afterQuery,
  Success,
  Loading,
});
