import { gql, useMutation } from '@apollo/client';
import { useCallback } from 'react';

const updateSelfAssessmentOptions = gql`
  mutation updateSelfAssessmentOptions(
    $studyId: ID!
    $input: SelfAssessmentOptionsInput!
  ) {
    updateSelfAssessmentOptions(studyId: $studyId, input: $input) {
      welcomeImage
      colorMain
      colorLight
      fontInclude
      fontFamily
      messages
      contactForm
    }
  }
`;

export const useStudyBrandingUpdate = () => {
  const [updateSAOMutation] = useMutation(updateSelfAssessmentOptions);

  const executeStudyBrandingUpdate = useCallback(
    (studyId, input) => updateSAOMutation({ variables: { studyId, input } }),
    [updateSAOMutation],
  );

  return [executeStudyBrandingUpdate];
};
