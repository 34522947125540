import { gql, useMutation } from '@apollo/client';
import { useCallback } from 'react';

const addStudy = gql`
  mutation addStudy($input: AddStudyInput!) {
    addStudy(input: $input) {
      id
    }
  }
`;

export const useAddStudy = () => {
  const [addStudyMutation] = useMutation(addStudy);

  const handleAddStudy = useCallback(
    input => addStudyMutation({ variables: { input } }),
    [addStudyMutation],
  );

  return handleAddStudy;
};
