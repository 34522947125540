export const formatCode = (value = '', oldValue = '') => {
  return value.replace(/[^\d]/g, '').substring(0, 6);
};

export const trimWhitespace = str => str.replace(/^\s+|\s+$/g, '');

/**
 * @see https://github.com/sindresorhus/escape-string-regexp
 */
export const escapeStringRegexp = string => {
  if (typeof string !== 'string') return '';
  return string
    .replace(/[|\\{}()[\]^$+?.]/g, '\\$&')
    .replace(/[*]/g, '.*')
    .replace(/-/g, '\\x2d');
};
