import { useAuth } from '@lib/authentication';
import { Box, Container, Stack } from '@mui/material';
import { useIntl } from 'react-intl';
import StudyList from 'src/admin/components/StudyList';
import LoggedInGuard from 'src/guards/LoggedInGuardNext';
import StudyLayout from 'src/layouts/StudyLayout';

function StudiesLandingPage() {
  const intl = useIntl();

  return (
    <LoggedInGuard>
      <StudyLayout scrollContent>
        <Container maxWidth="xl">
          <Stack sx={{ mb: 10 }} spacing={2}>
            <WelcomeTitle />

            <Box
              sx={{
                typography: 'body1',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              {intl.formatMessage({
                defaultMessage: 'Please select a study to get started!',
              })}
            </Box>
          </Stack>
          <StudyList />
        </Container>
      </StudyLayout>
    </LoggedInGuard>
  );
}

const WelcomeTitle = () => {
  const intl = useIntl();
  const { user } = useAuth();

  return (
    <Box
      sx={{
        mt: 2,
        typography: 'h2',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      {intl.formatMessage(
        { defaultMessage: 'Welcome {user}' },
        { user: user.firstName },
      )}
    </Box>
  );
};

export default StudiesLandingPage;
