import { useDisclosure } from '@lib/hooks';
import { logError } from '@lib/logging';
import { useDialog } from '@lib/react-hook-dialog';
import FilterChip from '@lib/tb-design/FilterChip';
import SearchIcon from '@mui/icons-material/Search';
import { Box, Grid, InputAdornment, TextField } from '@mui/material';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import AddStudyDialog from 'src/admin/components/AddStudyDialog';
import { useAddStudy } from 'src/admin/study';
import StudyList from './StudyList';

function StudiesPage() {
  const intl = useIntl();
  const router = useRouter();
  const addStudy = useAddStudy();
  const { showAlert } = useDialog();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [filterText, setFilterText] = useState('');
  const [filterStatus, setFilterStatus] = useState(['ACTIVE', 'DRAFT']);

  const handleCreateNewStudy = async input => {
    try {
      const response = await addStudy(input);
      router.push(`/admin/studies/${response.data.addStudy.id}`);
    } catch (err) {
      showAlert({
        title: intl.formatMessage({ defaultMessage: 'Error creating study' }),
        message: err.message,
        confirmText: intl.formatMessage({ defaultMessage: 'Ok' }),
      });
      logError(err);
    }
  };

  return (
    <>
      <Grid container spacing={4}>
        <Grid item xs={6}>
          <FilterChip
            label={intl.formatMessage({ defaultMessage: 'Study status' })}
            defaultValue={filterStatus}
            options={statuses}
            onChange={setFilterStatus}
          />
        </Grid>

        <Grid item xs={6}>
          <Box display="flex" justifyContent="flex-end">
            <TextField
              placeholder="Search"
              value={filterText}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon sx={{ color: 'grey.800' }} />
                  </InputAdornment>
                ),
              }}
              onChange={e => setFilterText(e.target.value)}
            />
          </Box>
        </Grid>

        <StudyList
          statuses={filterStatus}
          filterText={filterText}
          onAddStudy={onOpen}
        />
      </Grid>

      <AddStudyDialog
        open={isOpen}
        closeDialog={onClose}
        onSubmit={handleCreateNewStudy}
      />
    </>
  );
}

const statuses = [
  { label: 'Active', value: 'ACTIVE' },
  { label: 'Draft', value: 'DRAFT' },
  { label: 'Inactive', value: 'INACTIVE' },
  { label: 'Archived', value: 'DELETE' },
];

export default StudiesPage;
