import Add from '@mui/icons-material/Add';
import { Button, Stack, Typography } from '@mui/material';
import { useIntl } from 'react-intl';

/**
 * @param {object} props
 * @param {function} props.onClick
 */
export const AddStudyButton = ({ onClick }) => {
  const intl = useIntl();

  return (
    <Button
      color="primary"
      variant="contained"
      sx={{ boxShadow: '0px 1px 5px #00000014', height: '100%' }}
      onClick={() => onClick()}
      fullWidth
    >
      <Stack alignItems="center" justifyContent="center">
        <Add sx={{ fontSize: 56 }} />
        <Typography variant="subtitle1">
          {intl.formatMessage({ defaultMessage: 'New Study' })}
        </Typography>
      </Stack>
    </Button>
  );
};
