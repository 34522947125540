import { gql, useLazyQuery } from '@apollo/client';
import { get } from 'lodash';
import { useCallback, useMemo } from 'react';

export const QUERY = gql`
  query suggestStudyId($id: ID!) {
    suggestStudyId(id: $id)
  }
`;

export const useSuggestStudyId = () => {
  const [suggestIdQuery, suggestIdResult] = useLazyQuery(QUERY, {
    fetchPolicy: 'network-only',
  });

  const fetchSuggestedId = useCallback(
    id => suggestIdQuery({ variables: { id } }),
    [suggestIdQuery],
  );

  const id = get(suggestIdResult, 'variables.id');
  const suggestion = get(suggestIdResult, 'data.suggestStudyId');
  const suggestedId = useMemo(() => (id ? { [id]: suggestion } : {}), [
    id,
    suggestion,
  ]);

  return { fetchSuggestedId, suggestedId };
};
