import { useCheckAccess } from '@lib/authentication';
import SettingsIcon from '@mui/icons-material/Settings';
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Chip,
  IconButton,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { get, truncate } from 'lodash';
import { useIntl } from 'react-intl';
import LinkNext from 'src/components/LinkNext';

const useStyles = makeStyles(theme => ({
  cardContent: {
    display: 'flex',
    padding: theme.spacing(1),
  },
  hoverShadow: {
    '&:hover': {
      boxShadow: `0px 2px 8px 2px ${theme.palette.grey['300']}`,
    },
  },
  noHighlight: {
    background: 'none',
  },
  greyText: {
    color: theme.palette.grey['700'],
  },
  statusLine: {
    alignSelf: 'stretch',
    width: '4px',
    borderRadius: '2px',
    marginRight: '12px',
  },
  success: {
    backgroundColor: theme.palette.success.main,
  },
  warning: {
    backgroundColor: theme.palette.warning.main,
  },
  error: {
    backgroundColor: theme.palette.error.main,
  },
  inactive: {
    backgroundColor: theme.palette.grey[400],
  },
  default: {
    backgroundColor: theme.palette.grey[300],
  },
}));

const useModuleChipsStyles = makeStyles(theme => ({
  modulesBox: {
    margin: theme.spacing(1, 0),
    minHeight: '24px',
  },
  moduleChip: {
    color: 'black',
    backgroundColor: theme.palette.grey[200],
    marginRight: theme.spacing(1),
    '&:last-child': { marginRight: 0 },
  },
}));

const ModuleChips = ({ featureOptions }) => {
  const classes = useModuleChipsStyles();

  let chipTitles = [];

  // Chips should be displayed in the order: Match, Enroll, Consent, Engage.
  // "Match" does not exist yet and is therefore skipped for now.
  if (featureOptions?.recruitmentPortal) {
    chipTitles.push('Enroll');
  }

  if (featureOptions?.consenteesPortal) {
    chipTitles.push('Consent');
  }

  return (
    <Typography variant="body2" component="div">
      <Box className={classes.modulesBox}>
        {chipTitles.map(title => (
          <Chip
            size="small"
            label={title}
            className={classes.moduleChip}
            key={title}
          />
        ))}
      </Box>
    </Typography>
  );
};

export function StudyCard({
  id,
  internalStudyName,
  status,
  sites,
  locales,
  createdAt,
  featureOptions,
}) {
  const intl = useIntl();
  const classes = useStyles();
  const { checkAccess } = useCheckAccess();

  const classForStatus = status => {
    switch (status) {
      case 'ACTIVE':
      case 'PUBLISHED':
        return classes.success;
      case 'DRAFT':
        return classes.warning;
      case 'INACTIVE':
        return classes.inactive;
      case 'DELETE':
        return classes.error;
      default:
        return classes.default;
    }
  };

  return (
    <Card variant="outlined" className={classes.hoverShadow}>
      <CardActionWrapper id={id} features={featureOptions}>
        <CardContent className={classes.cardContent}>
          <Box className={clsx(classes.statusLine, classForStatus(status))} />
          <Box width="100%">
            <Box
              sx={{
                width: '100%',
                typography: 'subtitle1',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              {truncate(internalStudyName, { length: 24 })}

              {checkAccess({ actions: ['study:update'] }) && (
                <IconButton component={LinkNext} href={`/admin/studies/${id}`}>
                  <SettingsIcon />
                </IconButton>
              )}
            </Box>
            <Typography variant="overline">{id}</Typography>
            <ModuleChips featureOptions={featureOptions} />
            <Typography variant="caption" display="block">
              {intl.formatMessage(
                { defaultMessage: '{siteCount} sites, {localeCount} locales' },
                {
                  siteCount: get(sites, 'length', 0),
                  localeCount: get(locales, 'length', 0),
                },
              )}
            </Typography>
            <Typography
              variant="caption"
              display="block"
              className={classes.greyText}
            >
              {intl.formatMessage(
                { defaultMessage: 'Created {date}' },
                {
                  date: intl.formatDate(createdAt, {
                    day: 'numeric',
                    month: 'short',
                    year: 'numeric',
                  }),
                },
              )}
            </Typography>
          </Box>
        </CardContent>
      </CardActionWrapper>
    </Card>
  );
}

const CardActionWrapper = ({ id, features, children }) => {
  const classes = useStyles();
  const { checkAccess } = useCheckAccess();
  const isClickAllowed = checkAccess({ actions: ['study:get'] });

  if (isClickAllowed) {
    return (
      <CardActionArea
        classes={{ focusHighlight: classes.noHighlight }}
        href={buildStudyUrl(id, features)}
        component={LinkNext}
        linkComponent="div"
        noLinkStyle
      >
        {children}
      </CardActionArea>
    );
  } else {
    return <>{children}</>;
  }
};

const buildStudyUrl = (id, features) => {
  let feature;

  if (features?.recruitmentPortal) {
    feature = 'recruitmentPortal';
  } else if (features?.consenteesPortal) {
    feature = 'consenteesPortal';
  }

  return `/studies/${id}/${feature}`;
};
