import { gql, useMutation } from '@apollo/client';
import { useCallback } from 'react';

const updateStudy = gql`
  mutation updateStudy($input: UpdateStudyInput!) {
    updateStudy(input: $input) {
      id
      status
      internalStudyName
      sponsor
      createdAt
      updatedAt
      archivedAt
      locales {
        locale
      }
    }
  }
`;

export const useStudyUpdate = () => {
  const [updateStudyMutation] = useMutation(updateStudy);

  const executeStudyUpdate = useCallback(
    input => {
      return updateStudyMutation({ variables: { input } });
    },
    [updateStudyMutation],
  );

  return [executeStudyUpdate];
};
