import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  Link,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { debounce, isEmpty, startCase } from 'lodash';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useSuggestStudyId } from 'src/admin/study';
import { FEATURES } from 'src/constants';
import DialogForm from './DialogForm';

const getNewId = (string = '') =>
  string
    .toUpperCase()
    .replace(/[^A-Z0-9]/g, '')
    .substr(0, 5);

const errorId = (id, suggestions) =>
  Boolean(id && suggestions[id] !== undefined && suggestions[id] !== id);

const HelperTextId = (id, suggestions, setId) => {
  const suggestion = suggestions[id];
  if (errorId(id, suggestions) && suggestion) {
    return (
      <Typography variant="caption">
        The study id is already taken.
        <Link
          component="button"
          variant="caption"
          onClick={() => setId({ target: { value: suggestion } })}
        >
          Suggested id: {suggestion}
        </Link>
      </Typography>
    );
  } else if (errorId(id, suggestions) && !suggestion) {
    return <Typography variant="caption">Choose another one.</Typography>;
  } else if (suggestion === id) {
    return (
      <Box color="success.main">
        <Typography variant="caption" color="inherit">
          The study id is free. You can continue.
        </Typography>
      </Box>
    );
  } else {
    return (
      <Typography variant="caption">Unique id, 1-5 characters.</Typography>
    );
  }
};

const debouncedIdCheck = debounce(
  ({ newId, suggestStudyIdResult, suggestStudyId }) => {
    if (newId && !suggestStudyIdResult[newId]) suggestStudyId(newId);
  },
  3000,
);

/**
 * @param {object} props
 * @param {boolean} props.open
 * @param {function} props.closeDialog
 * @param {function} props.onSubmit
 */
function AddStudyDialog({ open = false, closeDialog, onSubmit }) {
  const intl = useIntl();
  const [id, setId] = useState('');
  const [internalStudyName, setInternalStudyName] = useState('');
  const [sponsor, setSponsor] = useState('');
  const [features, setFeatures] = useState(FEATURES.slice(0, 1));

  const { fetchSuggestedId, suggestedId } = useSuggestStudyId();

  function close() {
    debouncedIdCheck.cancel();
    setId('');
    setInternalStudyName('');
    setSponsor('');
    setFeatures(FEATURES.slice(0, 1));
    closeDialog();
  }

  function onIdChange({ target }) {
    const newId = getNewId(target?.value);
    debouncedIdCheck({
      newId,
      suggestStudyIdResult: suggestedId,
      suggestStudyId: fetchSuggestedId,
    });
    setId(newId);
  }

  function onIdBlur({ target }) {
    debouncedIdCheck.cancel();
    const newId = getNewId(target?.value);
    fetchSuggestedId(newId);
    setId(newId);
  }

  const handleSubmit = () =>
    onSubmit({
      id,
      internalStudyName,
      sponsor: isEmpty(sponsor) ? undefined : sponsor,
      selfAssessmentOptions: { recaptchaVersion: 'NONE' },
      featureOptions: createFeatures(features),
    });

  return (
    <DialogForm
      open={open}
      aria-labelledby="add-study-dialog"
      title={intl.formatMessage({ defaultMessage: 'New study' })}
      onClose={close}
      maxWidth="sm"
      actions={[
        <Button key="cancel" variant="outlined" color="primary" onClick={close}>
          {intl.formatMessage({ defaultMessage: 'Cancel' })}
        </Button>,
        <Button
          key="submit"
          color="primary"
          variant="contained"
          disabled={[id, internalStudyName, features].some(isEmpty)}
          onClick={handleSubmit}
        >
          {intl.formatMessage({ defaultMessage: 'Create study' })}
        </Button>,
      ]}
    >
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <TextField
            required
            fullWidth
            variant="outlined"
            label={intl.formatMessage({ defaultMessage: 'Study Id' })}
            helperText={HelperTextId(id, suggestedId, onIdBlur)}
            error={errorId(id, suggestedId)}
            value={id}
            onChange={onIdChange}
            onBlur={onIdBlur}
            inputProps={{
              minLength: 1,
              maxLength: 5,
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            variant="outlined"
            label={intl.formatMessage({
              defaultMessage: 'Internal study name',
            })}
            helperText={intl.formatMessage({
              defaultMessage:
                'Displayed in candidate manager, visible to site users.',
            })}
            value={internalStudyName}
            onChange={e => setInternalStudyName(e.target.value)}
          />
        </Grid>

        <Grid item xs={8}>
          <TextField
            fullWidth
            variant="outlined"
            label={intl.formatMessage({ defaultMessage: 'Sponsor name' })}
            helperText=" "
            value={sponsor}
            onChange={e => setSponsor(e.target.value)}
          />
        </Grid>

        <Grid item xs={12}>
          <FormControl fullWidth variant="outlined">
            <InputLabel id="demo-simple-select-readonly-label">
              {intl.formatMessage({ defaultMessage: 'Features' })}
            </InputLabel>
            <Select
              labelId="demo-simple-select-readonly-label"
              multiple
              value={features}
              onChange={e => setFeatures(e.target.value)}
              label={intl.formatMessage({ defaultMessage: 'Features' })}
            >
              {FEATURES.map(featureOption => (
                <MenuItem key={featureOption} value={featureOption}>
                  {startCase(featureOption)}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText> </FormHelperText>
          </FormControl>
        </Grid>
      </Grid>
    </DialogForm>
  );
}

const createFeatures = features =>
  Object.fromEntries(FEATURES.map(key => [key, features.includes(key)]));

export default AddStudyDialog;
