import { gql, useMutation } from '@apollo/client';
import { useCallback } from 'react';

const ARCHIVE_STUDY = gql`
  mutation WEB_StudySetArchiveStatus($studyId: ID!) {
    archiveStudy(id: $studyId) {
      id
      status
      archivedAt
    }
  }
`;

export const useArchiveStudy = () => {
  const [archiveStudy] = useMutation(ARCHIVE_STUDY);

  const executeArchiveStudy = useCallback(
    studyId => {
      return archiveStudy({ variables: { studyId } });
    },
    [archiveStudy],
  );

  return [executeArchiveStudy];
};
